import React, { createContext, useEffect } from 'react'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import GlobalStyle from '../GlobalStyles'
import logo from '../assets/images/logo-branco.png'
import styled from 'styled-components'
import {
    contextImg,
} from '../images/redirectLab'
import BgFundo  from '../assets/images/redirectLab/bgAzul.png'
import { graphql } from 'gatsby'

export const RedirectLab = styled.div`
    font-family: 'ASICSFont3.0-Regular', sans-serif;
    background-color: #001E62;
    background-image: url(${BgFundo});
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    min-height: 100vh;
    width: 100%;
    margin-top: -70px;
    padding-right: 16px;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
`

export const BoxConteudo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background: linear-gradient(180deg, #F7F1FF 0%, #D2C9FF 100%);
    width: 75vw;
    max-width: 950px;
    @media (max-width: 945px){
      padding: 20px 0;
    }
`

export const BoxTexts = styled.div`
    padding: 0px 15px 0px 30px;
    h1{
      color: #001E62;
      font-size: 35px;
      font-style: italic;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -1px;
      span{
        font-weight: 700;
      }
    }
    p{
      color: #001E62;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px;
      padding-top: 15px;
      padding-bottom: 10px;
    }
    h2{
      color: #001E62;
      font-size: 25px;
      font-style: italic;
      font-weight: 700;
      line-height: 30px; 
      padding-bottom: 30px;
      padding-top: 25px;
    }
    a{
      border-radius: 175.355px;
      background: #001E62;
      color: #FFF;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 110% */
      text-transform: uppercase;
      text-decoration: none;
      padding: 12px 35px;
    }
    @media (max-width: 945px){
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px 30px;
    }
`
export const BoxImgContext = styled.div`
    width: 100%;
    display: flex;
    width: 100%;
    @media (max-width: 945px){
        display: none;
    }
`


const HomePage = () => {
  const { t } = useTranslation()
  return (
      <RedirectLab>
        <GlobalStyle />
        <BoxConteudo>
          <BoxTexts>
            <h1><Trans>O<Trans> <span>ASICS Lab</span> agora está na</Trans> <strong>asics.com.br</strong></Trans></h1>
            <p>
              <Trans>Reunimos as principais informações sobre lançamentos, calçados, tecnologias, dicas de treinamento e notícias do mundo esportivo no portal da ASICS e agora você tem tudo em um só lugar.</Trans>
            </p>
            <h2>
              <Trans>Acesse o portal clicando no botão abaixo:</Trans>
            </h2>
            <a href="https://www.asics.com.br/">
              <Trans>Ir para o Site Oficial da ASICS</Trans> 
            </a>
          </BoxTexts>
          <BoxImgContext>
            <img src={contextImg} alt="" />
          </BoxImgContext>
        </BoxConteudo>
        <div>
          <img src={logo} alt="Asics" width="142" height="48" />
        </div>
      </RedirectLab>  
  )
}

export default HomePage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`