import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: #001E62;
    --secondary-color: #485cc7;
    --thirdy-color: #b6b8dc;
    --beginner-bgColor: #dceee2;
    --beginner-color: #00746d;
    --intermediary-bgColor: #e4e5f3;
    --intermediary-color: #485cc7;
    --advanced-bgColor: #ffecdc;
    --advanced-color: #b9411d;
    --fontBold: 'ASICSFont3.0-Bold', sans-serif;
    --pisada-supinada: #00abfc;
    --pisada-neutra: #00c861;
    --pisada-pronada: #f86b49;
    --fontBoldItalic: 'ASICSFont3.0-BoldItalic';
    --fontRegularItalic: 'ASICSFont3.0-Regular', sans-serif;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    scroll-padding-top: 90px;

    &.smooth-scroll {
        scroll-behavior:smooth;
    }
  }

  body {
    font-family: 'ASICSFont3.0-Regular', sans-serif !important;
    font-size: 16px;
    overflow-x: hidden;
    padding-top: 70px;
    -webkit-font-smoothing: antialiased;
  }

  @media (min-width: 1280px) {
    .MuiContainer-maxWidthLg {
        max-width: 1100px !important;
    }
  }

  .swiper-wrapper {
    margin-bottom: 1.875rem;
  }
  
  .swiper-pagination-bullet {
    background: var(--thirdy-color);
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: var(--primary-color);
  }

  .swiper-button-next, .swiper-button-prev {
    color: var(--primary-color);

    @media(max-width: 767px) {
      display: none;
    }
  }

  .carrossel-home {
    .swiper-pagination {
      @media(min-width: 767px) {
        display: none;
      }
    }
  }

  .carrossel-home,
  .banner-img {
    max-width: 1920px;
  }

  .banner-img {
    display: block;
    margin: 0 auto;
  }

  .visible-xs {
    display:none;

    @media (max-width:767px) {
      display:block;
    }
  }

  &.hidden-xs {
    @media (max-width:767px) {
        display:none;
    }
  }

  .mask-input {
    background-color: #fff;
    border: 1px solid #FFF;
    border-radius: 1.875rem;
    color: var(--primary-color);
    font-weight: bold;
    font-style: italic;
    font-size: 12px;
    height: 3.125rem;
    padding: 0 20px;
    text-transform: uppercase;
    transition: border .3s ease;
    width: 100%;

    &:focus {
      outline: none;
      border: 1px solid var(--thirdy-color);
    }

    &::placeholder {
      color: var(--thirdy-color);
      font-weight: bold;
      font-style: italic;
      font-size: 12px;
      text-transform: uppercase;
    }

    &:invalid {
      border: 1px solid red;
    }

    &.erro {
      border: 1px solid red !important;
    }

    &.isLP {
      border-color: #5467cb;
    }
  }
  .w-full{
        width: 100%;
    }
`

export default GlobalStyle
